import React, { useState } from 'react';

import Image from "next/legacy/image";
import classnames from 'classnames';

import { generateSrcSet, loaderImage, loaderStaticImage } from '../../../utils/image';

const avatarImageFallback = `static/images/placeholder/avatar.png`;

type Props = {
  src: string,
  onClick?: () => void,
  role?: string,
  alt?: any,
  ariaLabel?: string,
  classname?: string,
  tabIndex?: number
  width?: number
  height?: number
  quality?: number
  priority?: boolean
}

const AvatarImage = (props: Props) => {
  const [ lastImage, setLastImage] = useState('');
  const { ariaLabel, tabIndex = -1, role } = props;

  const onError = (e) => {
    if (lastImage !== e.currentTarget.src) {
      const srcImage = loaderStaticImage({ src: avatarImageFallback });
      setLastImage(e.currentTarget.src)
      e.currentTarget.src = srcImage;
      e.currentTarget.srcset = generateSrcSet({ src: srcImage});
    }
  };

  return <Image
    src={props?.src || loaderStaticImage({ src: avatarImageFallback })}
    className={classnames('image-avatar', props?.classname)}
    loader={loaderImage}
    alt={props?.alt || ''}
    width={props.width || 50}
    height={props.height || 50}
    layout='responsive'
    quality={props.quality || 50}
    priority={props.priority || false}
    tabIndex={tabIndex}
    role={role || null}
    aria-label={ariaLabel || null}
    onError={onError}
    onClick={props?.onClick}
  />;
};


export default React.memo(AvatarImage);
