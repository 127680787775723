import React, { useState, useEffect, PropsWithChildren } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import LazyHydrate from 'react-lazy-hydration';
import cookies from 'react-cookies';
import config from '../../../env.config';
import { generateURLQueryString } from '../../utils/url';

const MainFooter = dynamic(
  () => import('@hotmart/app-display-components/dist/ProductPage').then(
    (module) => module.default.MainFooter
  ),
  { ssr: false }
);

type Props = {
  language: string;
  showFooter?: Boolean;
  showFooterView?: Boolean;
  simplified?: Boolean;
  project?: string;
  categoriesLinks?: string;
  isMarketplace?: Boolean;
  hideLanguageSwitch?: string;
  children?: React.ReactNode;
};

function Footer(props: PropsWithChildren<Props>) {
  const {
    language,
    showFooter,
    showFooterView,
    project,
    simplified,
    categoriesLinks,
    isMarketplace,
    hideLanguageSwitch,
    children
  } = props;
  const [footer, setFooter] = useState({});
  const router = useRouter();
  const { lng, sck, src, pp } = router.query;

  const changeLanguage = (lng: string) => {
    lng = lng.match(/pt/) ? 'pt-br' : lng;

    cookies.save('hmLangCookie', lng, {
      path: '/',
      domain: window.location.hostname.replace('www.', '')
    });

    const finalQuery = {
      lng,
      ...(sck ? { sck } : {}),
      ...(src ? { src } : {}),
      ...(pp ? { pp } : {})
    };

    const urlWithLanguage = generateURLQueryString(window.location.pathname, finalQuery);

    router.push(urlWithLanguage, urlWithLanguage);
  };

  const loadFakeFooter = () => {
    return <div
      className="hm-mktplace-footer footer-resumed"
      data-testid="hm-mktplace-footer"
    ></div>;
  };

  const loadingFooter = () => {
    const footerLoaded = (
      <LazyHydrate whenIdle>
        <MainFooter // @ts-ignore
          project={project}
          simplified={simplified}
          showFooter={showFooter}
          changeLanguage={changeLanguage}
          language={lng || language}
          environment={config.currentEnv}
          categoriesLinks={categoriesLinks}
          isMarketplace={isMarketplace}
          hideLanguageSwitch={hideLanguageSwitch}
          children={children}
        />
      </LazyHydrate>
    );
    setFooter(footerLoaded);
  };

  useEffect(() => {
    loadingFooter();
  }, [showFooterView, lng]);

  const renderFoooter = () => {
    return showFooterView ? footer : loadFakeFooter();
  };

  return (
    <div id="hm-mktplace-footer">
      <>{renderFoooter()}</>
    </div>
  );
}

export default React.memo(Footer);
