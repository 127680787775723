import React from 'react';
import Rating from '../../Rating';
import PercentageBar from '../../PercentageBar';
import { ReviewsBoxProps } from '../types';

type PercentageProps = {
  percentageObj: ReviewsBoxProps;
};

const RatingGrid = ({ percentageObj }: PercentageProps) => {
  return (
    <div className="rating-grid">
      {[5, 4, 3, 2, 1].map((star) => (
        <div className="star-detail" key={`star-${star}`}>
          <div className="qty-stars">
            <Rating
              value={star}
              showRegularAsSolid
              width={12}
              height={12}
              showSolid
            />
          </div>
          <div className="qty-stars-bar">
            <PercentageBar percentage={percentageObj[star]} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default RatingGrid;
