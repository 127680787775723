import React from 'react';
import IconsImage from '../Images/Icons';
import { loaderStaticImage } from '../../utils/image';

const Times = loaderStaticImage({ src: 'static/images/icons/times.svg' });
const ArrowRight = loaderStaticImage({
  src: 'static/images/icons/arrow-right.svg'
});
const ArrowRightSolid = loaderStaticImage({
  src: 'static/images/icons/arrow-right-solid.svg'
});

const LinkArrow = (props) => {
  const {
    children,
    className,
    iconBackground,
    iconPrefix,
    iconName,
    arrowLeft,
    ...rest
  } = props;

  const renderIcon = (iconName) => {
    switch (iconName) {
      case 'times':
        return <IconsImage src={Times} width={62} height={62} />;
      default:
        return (
          <IconsImage
            className="arrow-icon arrow-left"
            src={!iconBackground ? ArrowRight : ArrowRightSolid}
            width={62}
            height={62}
          />
        );
    }
  };

  return (
    <a className={`link-arrow ${className}`} {...rest}>
      {arrowLeft && (
        <div className="icon icon-left" style={{ background: iconBackground }}>
          {renderIcon(iconName)}
        </div>
      )}
      <span className="link-arrow-text">{children}</span>
      {!arrowLeft && (
        <div className="icon" style={{ background: iconBackground }}>
          {renderIcon(iconName)}
        </div>
      )}
    </a>
  );
};

export default LinkArrow;
