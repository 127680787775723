import React from 'react';
import PropTypes from 'prop-types';

import IconsImage from '../Images/Icons';
import { loaderStaticImage } from '../../utils/image';

const StarHalfAlt = loaderStaticImage({
  src: 'static/images/icons/star-half-alt.svg'
});
const Star = loaderStaticImage({ src: 'static/images/icons/star.svg' });
const StarSolid = loaderStaticImage({
  src: 'static/images/icons/star-solid.svg'
});
const StarLight = loaderStaticImage({
  src: 'static/images/icons/start-light.svg'
});

type Props = {
  showRegularAsSolid?: boolean;
  showSolid?: boolean;
  value: number;
  width?: number;
  height?: number;
};

const Rating = ({
  showRegularAsSolid,
  showSolid,
  value = 1,
  width = 16,
  height = 16
}: Props) => {
  const generateItems = () => {
    const v = value;
    const items = [];

    if (!showSolid) {
      items.push(
        <IconsImage
          src={Star}
          key={v}
          className="star"
          width={width}
          height={height}
        />
      );
    } else {
      for (let i = 1, j = 5; i <= j; i++) {
        if (v >= i) {
          items.push(
            <IconsImage
              src={StarSolid}
              key={i}
              className="star"
              width={width}
              height={height}
            />
          );
        } else if (v > i - 1) {
          if (showRegularAsSolid) {
            items.push(
              <IconsImage
                src={StarHalfAlt}
                key={i}
                className="star star-half"
                width={width}
                height={height}
              />
            );
          }
        } else if (showRegularAsSolid) {
          items.push(
            <IconsImage
              src={StarLight}
              key={i}
              className="star star-light"
              width={width}
              height={height}
            />
          );
        }
      }
    }

    return items;
  };

  return (
    <div className="rating rating--title colorText">{generateItems()}</div>
  );
};

Rating.propTypes = {
  value: PropTypes.any
};

export default React.memo(Rating);
