/* eslint-disable consistent-return */
/* eslint-disable no-console */
import Request from '@hotmart/request';
import { Constants } from './request';

class ServiceOnline {
  async getDates(ucode) {
    try {
      return Request(Constants.SERVICE_ONLINE_DAYS, { ucode });
    } catch (e) {
      console.log(e);
    }
  }

  async getHours({ ucode, day }) {
    try {
      return Request(Constants.SERVICE_ONLINE_HOURS, { ucode, day });
    } catch (e) {
      console.log(e);
    }
  }

  async getOffer({ ucode, selectedAttendance }) {
    try {
      return Request(Constants.SERVICE_ONLINE_OFFER, { ucode, selectedAttendance });
    } catch (e) {
      console.log(e);
    }
  }
}

export default new ServiceOnline();
