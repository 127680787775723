import React from 'react';
import { withTranslation } from 'next-i18next';
import classnames from 'classnames'
import Video from 'components/Video';
import Image from "next/legacy/image";
import { loaderImage } from '../../utils/image';
import LazyHydrate from 'react-lazy-hydration';
import { isValidVideoURL } from '../../utils/video';

const Cover = ({...props}) => {
  return (
    <div className="cover">
      {mountCover(props)}
    </div>
  )
};

const mountCover = (props) => {
  const { videoLink, dataSheet, isMobile, poster, productImage, title, description, date, isRedesigned, t } = props;
  const dateVideo = date ? new Date(date).toISOString() : new Date().toISOString();

  if (videoLink && isValidVideoURL(videoLink)) {
    return (
      <section
        id="video"
        ref={props?.coverRef}
        className={classnames({
          'section section-divider': !isRedesigned
        })}>
        <Video link={videoLink} isMobile={isMobile} productImage={productImage} poster={poster} title={title} description={description} date={dateVideo}/>
      </section>
    )
  }

  if (dataSheet?.cover) {
    const { videoLink, imageLink, imageLinkFinal } = dataSheet?.cover || {};
    if(videoLink && isValidVideoURL(videoLink)) {
      return (
        <section
          id="video"
          ref={props?.coverRef}
          className={classnames('section-video', {
            'section section-divider': !isRedesigned
          })}>
          <Video link={videoLink} isMobile={isMobile}/>
        </section>
      )
    } else if (imageLink || imageLinkFinal) {
      return (
        <section
          id="cover"
          className={classnames({
            'section section-divider': !isRedesigned
          })}>
          <LazyHydrate whenVisible>
            <div className="section" id="image-cover">
              <div className="image__wrapper">
                <Image
                  src={imageLinkFinal || imageLink}
                  loader={loaderImage}
                  className="image-cover"
                  priority={false}
                  layout={'fill'}
                  alt={t('product.video')}
                  quality={10}
                  loading={'lazy'}
                />
              </div>
            </div>
          </LazyHydrate>
        </section>
      )
    }
  }
};

export default withTranslation()(React.memo(Cover));
