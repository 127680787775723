import React from 'react';
import { DayPicker, useNavigation } from 'react-day-picker';
import { isSameDay, format } from 'date-fns';
import IconsImage from '../Images/Icons';
import { loaderStaticImage } from '../../utils/image';
import classnames from 'classnames';

import { MONTHS, LOCALES } from '../../utils/localization';

const ChevronLeft = loaderStaticImage({
  src: 'static/images/icons/chevron-left.svg'
});
const ChevronRight = loaderStaticImage({
  src: 'static/images/icons/chevron-right.svg'
});

const QuestionCircle = loaderStaticImage({
  src: '/static/images/icons/question-circle.svg'
});

const DayPickerContainer = ({
  locale = 'pt-br',
  highlightDays,
  selectDate,
  isSessionPackage,
  tooltip,
  setTooltip,
  t
}) => {
  const isDayDisabled = (day) => {
    return !highlightDays.some((disabledDay) => isSameDay(day, disabledDay));
  };

  const CustomCaption = ({ displayMonth }) => {
    const { goToMonth, nextMonth, previousMonth } = useNavigation();

    const months = MONTHS[locale] || MONTHS['pt-br'];
    const currentMonthIndex = displayMonth.getMonth();

    const currentMonth = format(displayMonth, 'MMMM yyyy', {
      locale: LOCALES[locale] || LOCALES['pt-br']
    });

    const monthYear =
      currentMonth.charAt(0).toUpperCase() + currentMonth.slice(1);

    const prevMonth = previousMonth ? months[previousMonth.getMonth()] : null;
    const nextMonthText = nextMonth ? months[nextMonth.getMonth()] : null;

    const isPrevDisabled =
      !previousMonth || currentMonthIndex <= new Date().getMonth();

    return (
      <div className="react-datepicker">
        <div className="react-datepicker__header-info">
          <h4>
            {t('online_service.choose_date')}
            {isSessionPackage && (
              <>
                <span
                  onMouseEnter={() => setTooltip(true)}
                  onMouseLeave={() => setTooltip(false)}
                >
                  <IconsImage
                    src={QuestionCircle}
                    width={20}
                    height={20}
                    className="react-datepicker__header-info__icon"
                  />
                </span>
                <div className="sessionPackageInfo__tooltip">
                  <div
                    className={classnames('tooltip-container', {
                      'show-tooltip': tooltip
                    })}
                  >
                    <div className="tooltip">
                      {t('online_service.choose_date_tooltip')}
                    </div>
                  </div>
                </div>
              </>
            )}
          </h4>

          <div className="react-datepicker__header-picker">
            <button
              className={`react-datepicker__header-button react-datepicker__header-button--previous ${
                isPrevDisabled ? 'disabled' : ''
              }`}
              disabled={isPrevDisabled}
              onClick={() => !isPrevDisabled && goToMonth(previousMonth)}
            >
              <IconsImage
                src={ChevronLeft}
                width={8}
                height={8}
                className="icon-chevron"
              />
              {prevMonth ? prevMonth.slice(0, 3) : ''}
            </button>
            <span className="react-datepicker__header-month-name">
              {monthYear}
            </span>

            <button
              className={`react-datepicker__header-button react-datepicker__header-button--next ${
                !nextMonth ? 'disabled' : ''
              }`}
              disabled={!nextMonth}
              onClick={() => nextMonth && goToMonth(nextMonth)}
            >
              {nextMonthText ? nextMonthText.slice(0, 3) : ''}
              <IconsImage
                src={ChevronRight}
                width={11}
                height={11}
                className="icon-chevron"
              />
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <DayPicker
        locale={LOCALES[locale] || LOCALES['pt-br']}
        months={MONTHS[locale] || MONTHS['pt-br']}
        selected={highlightDays}
        disabled={isDayDisabled}
        onDayClick={selectDate}
        components={{ Caption: CustomCaption }}
        weekStartsOn={1}
      />
    </div>
  );
};

export default DayPickerContainer;
