import React from 'react';
import classnames from 'classnames';
import ProductImage from '../../Images/Product';
import ProductLink from 'components/Link/Product/index';
import Utils from '../../../global/scripts/utils';
import '@cosmos/card/card.css';

const ProductCard = ({
  className = '',
  product,
  sectionTitle = '',
  onClick
}) => {
  const {
    avatarFinal,
    slug,
    producerReferenceCode,
    hotmarterName,
    format,
    name,
    locale
  } = product;

  const staticMediaURL = Utils.replaceThumborURL(avatarFinal);
  const titleSubstring = name ? Utils.substringText(name, 60) : '';

  return (
    <hot-card class={classnames('product-card', className)}>
      <ProductLink
        slug={slug}
        locale={locale}
        producerReferenceCode={producerReferenceCode}
        onClick={onClick}
        ariaLabel={`${sectionTitle} - ${name}`}
      >
        <ProductImage  
          productFormat={format}
          productName={name} 
          src={staticMediaURL} 
          priority={false} quality={30} />

        <div className="product-card__content">
          {titleSubstring && (
            <div className="product-card__title">{titleSubstring}</div>
          )}

          {hotmarterName && (
            <div className="product-card__author">{hotmarterName}</div>
          )}
        </div>
      </ProductLink>
    </hot-card>
  );
};

export default ProductCard;
