export const SYSTEM = 'display_search';

export const DEFAULT_EVENT_PROPS = {
  'system': SYSTEM,
  'data_version': '1.0'
};

export const TYPES = {
  SEARCH: 'display_search-term-search',
  REPLY: 'display_search-search-produce',
  CLICK: 'display_search-user-action-metrics'
};

export const EVENT_ACTIONS = {
  SEARCH: 'search',
  REPLY: 'reply',
  CLICK: 'click',
  VIEW: 'view',
  BUY: 'buy'
};

export const SEARCH_SOURCES = {
  TAG: "TAG",
  NO_RESULTS: "TAG_ORIGIN_PAGE_WIHTOUT_RESULT",
  NO_RESULTS_CATEGORY: "TAG_ORIGIN_PAGE_CATEGORY_WIHTOUT_RESULT",
  HOME: "TERM_ORIGIN_HOME_PAGE",
  SEASON: "TERM_ORIGIN_HOME_SEASON",
  SEARCH: "TERM_ORIGIN_SEARCH_PAGE",
  CATEGORY: "TERM_ORIGIN_CATEGORY_PAGE",
  HEADER: "TERM_ORIGIN_HEADER",
  HOTMART: "TERM_ORIGIN_HOTMART_COM"
}

export const CLICK_SOURCES = {
  RESULTS: 'RESULT_SEARCH_PAGE',
  CATEGORY_ALL: 'SECTION_CATEGORY_PAGE_ALL',
  CATEGORY_BEST_RATED: 'SECTION_CATEGORY_PAGE_BEST_RATED',
  CATEGORY_NEW: 'SECTION_CATEGORY_PAGE_NEW',
  CATEGORY_POPULAR: 'SECTION_CATEGORY_PAGE_POPULAR',
  HOME_ALL: 'SECTION_HOME_MARKETPLACE_ALL',
  HOME_BEST_RATED: 'SECTION_HOME_MARKETPLACE_BEST_RATED',
  HOME_NEW: 'SECTION_HOME_MARKETPLACE_NEW',
  HOME_FRESH: 'SECTION_HOME_MARKETPLACE_FRESH',
  PRODUCT_PAGE: 'SECTION_PRODUCT_PAGE'
}

export const REQUIRED_FIELDS = {
  [EVENT_ACTIONS.SEARCH]: [
    'term',
    'locale',
    'ip',
    'sessionId',
    'source',
    'path'
  ],
  [EVENT_ACTIONS.REPLY]: [
    'sessionId',
    'products',
    'searchId'
  ],
  [EVENT_ACTIONS.CLICK]: [
    'sessionId',
    'productId',
    'source'
  ],
  [EVENT_ACTIONS.VIEW]: [
    'sessionId',
    'productId',
    'source'
  ],
  [EVENT_ACTIONS.BUY]: [
    'sessionId',
    'productId'
  ],
  [EVENT_ACTIONS.PIXEL_INJECTION_ERROR]: [
    'context',
    'errorTrigger',
    'eventId',
    'runtimeValues',
    'userAgent'
  ]
};

export const EVENT_PROPS = {
  [EVENT_ACTIONS.SEARCH]: {
    ...DEFAULT_EVENT_PROPS,
    entity: 'term',
    action: 'search'
  },
  [EVENT_ACTIONS.REPLY]: {
    ...DEFAULT_EVENT_PROPS,
    entity: 'search_produce',
    action: 'reply'
  },
  [EVENT_ACTIONS.CLICK]: {
    ...DEFAULT_EVENT_PROPS,
    entity: 'user_action_metrics',
    action: 'click'
  },
  [EVENT_ACTIONS.BUY]: {
    ...DEFAULT_EVENT_PROPS,
    entity: 'product_page',
    action: 'buy'
  },
  [EVENT_ACTIONS.VIEW]: {
    ...DEFAULT_EVENT_PROPS,
    entity: 'product_page',
    action: 'view'
  },
  [EVENT_ACTIONS.PIXEL_INJECTION_ERROR]: {
    system: 'app_pixel',
    entity: 'injection',
    event_version: '1.1',
    data_version: '1.0',
    action: 'error'
  }
};