import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import ProductImage from '../../Images/Product';
import { withTranslation } from 'next-i18next';
import ProductLink from '../../Link/Product';
import Rating from 'components/Rating';
import Utils from '../../../global/scripts/utils';

const MiniProductCard = ({ className, product, t, onClick }) => {
  const {
    rating,
    totalReviews,
    category,
    avatarFinal,
    hotmarterName,
    slug,
    name,
    format,
    producerReferenceCode,
    coupon,
    locale
  } = product;
  const productCategory = category && t(`category.${product.category}.name`);
  const staticMediaURL = Utils.replaceThumborURL(avatarFinal);

  const title = Utils.substringText(name, 60);

  return (
    <div className={classnames('product-card-mini', className)}>
      <ProductLink
        className='product-card-mini__name'
        slug={slug}
        producerReferenceCode={producerReferenceCode}
        onClick={onClick}
        locale={locale}
      >
        <ProductImage
          src={staticMediaURL}
          priority={false}
          quality={10}
          alt={title}
          productFormat={format}
          productName={name}
          loading={'lazy'}
        />

        <div className='product-card-mini__content-wrapper'>
          {rating && (
            <div className='product-card-mini__rating'>
              <Rating
                value={rating}
                className='product-card-mini__rating__stars'
              />{' '}
              {totalReviews && (
                <span className='product-card-mini__rating__total'>
                  ({totalReviews})
                </span>
              )}
            </div>
          )}

          <div className='product-card-mini__content'>
            <div className='product-card-mini__title'>{title}</div>

            {hotmarterName && <div className='product-card-mini__author'>{hotmarterName}</div>}
          </div>

          <div className='product-card-mini__footer'>
            {category && (
              <div className='product-card-mini__category'>
                {productCategory}
              </div>
            )}
          </div>
        </div>
      </ProductLink>
    </div>
  );
};

MiniProductCard.propTypes = {
  className: PropTypes.string,
  product: PropTypes.object.isRequired,
  onClick: PropTypes.func
};

MiniProductCard.defaultProps = {
  className: '',
  onClick: () => {}
};

export default withTranslation()(MiniProductCard);
