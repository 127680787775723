import React, { useEffect, useState } from 'react';
import { getIframeSrcURL, isIframe } from '../../utils/iframe';
import {
  isValidVideoURL,
  generateVideoEmbed,
  getVideoCodeAndPlayer
} from '../../utils/video';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import classnames from 'classnames';
import { getVimeoEmbed } from './options';
import { VideoObject } from 'schema-dts';
import { JsonLd } from 'react-schemaorg';

type VideoProps = {
  link: string;
  isMobile: boolean;
  poster?:
    | 'default'
    | 'mqdefault'
    | 'hqdefault'
    | 'sddefault'
    | 'maxresdefault';
  productImage?: string;
  title?: string;
  date?: string;
  description?: string;
};

type VimeoProps = {
  component: JSX.Element;
  thumb: { [key: string]: string };
};

const Video = ({
  link,
  isMobile,
  poster,
  productImage,
  title,
  date,
  description
}: VideoProps) => {
  const [showVideo, setShowVideo] = useState(true);
  const [videoFrame, setVideoFrame] = useState(null);
  const [showVideoEmbed, setShowVideoEmbed] = useState(false);
  const [videoInfo, setVideoInfo] = useState({});
  const [videoStructuredData, setVideoStructuredData] = useState(null);

  let videoURL = link;
  const onVideoError = (error, message) => {
    setShowVideo(error);
  };

  const mountVideoStructuredData = ({
    title,
    description,
    date,
    videoInfo
  }) => {
    if (videoInfo && !videoInfo.video) {
      return false;
    }

    const featuredDescription = description
      ? description.slice(0, 200).replace(/"/g, '\\"') + '…'
      : '';
    return (
      (<JsonLd<VideoObject>
          item={{
            '@context': 'https://schema.org',
            '@type': 'VideoObject',
            name: title?.replace(/"/g, '\\"'),
            description: featuredDescription,
            contentUrl: videoInfo.video,
            embedUrl: videoInfo.embed,
            thumbnailUrl: videoInfo?.thumb || productImage,
            uploadDate: date
          }}
        />)
    );
  };

  useEffect(() => {
    setVideoStructuredData(
      mountVideoStructuredData({ date, description, title, videoInfo })
    );
  }, [videoInfo]);

  useEffect(() => {
    if (isIframe(link)) {
      videoURL = getIframeSrcURL(link);
    }

    if (isValidVideoURL(videoURL)) {
      const videoEmbedURL = generateVideoEmbed(videoURL);
      const playerVideoandCode = getVideoCodeAndPlayer(videoURL);

      if (videoEmbedURL) {
        if (playerVideoandCode?.player === 'YOUTUBE') {
          if (!videoFrame) {
            setVideoInfo({
              video: `https://www.youtube.com/watch?v=${playerVideoandCode?.code}`,
              embed: `https://www.youtube-nocookie.com/embed/${playerVideoandCode?.code}`,
              thumb: `https://i.ytimg.com/vi/${playerVideoandCode?.code}/${poster}.jpg`
            });
            setVideoFrame(
              <div className={`videoWrapper`} id="iframeWrapper">
                <LiteYouTubeEmbed
                  id={playerVideoandCode?.code}
                  adNetwork={!isMobile}
                  params=""
                  playlist={false}
                  poster={poster}
                  title="Video"
                  noCookie={true}
                />
              </div>
            );
          }
        }

        if (playerVideoandCode?.player === 'VIMEO') {
          if (!videoFrame) {
            getVimeoEmbed(
              videoEmbedURL,
              onVideoError,
              showVideoEmbed,
              setShowVideoEmbed,
              setVideoFrame,
              productImage
            ).then((data: VimeoProps) => {
              setVideoInfo({
                video: `https://vimeo.com/${playerVideoandCode?.code}`,
                embed: `https://player.vimeo.com/video/${playerVideoandCode?.code}`,
                thumb: data?.thumb?.thumbnail_url
              });
              setVideoFrame(data?.component || data);
            });
          }
        }
      }
    }
  }, [isMobile, videoFrame]);

  return (
    <div
      className={classnames('section', {
        hide: !showVideo,
        section_video: videoFrame
      })}
    >
      {videoStructuredData}
      <div className="section_wrap">{videoFrame}</div>
    </div>
  );
};

export default React.memo(Video);
