import Utils from 'global/scripts/utils';
import Request from '@hotmart/request';
import { sendAnalyticsEvent } from 'utils/datalayer';
import { Constants } from './request';
import config from '../../env.config';
import { MODULES_CLUB } from '../constants';

class ProfileService {
  async getProfile(query) {
    const { slug, preview, origin, draft, unpublished, reference } = query;

    try {
      if (preview && origin === 'backoffice') {
        return Request(Constants.PRODUCT_PREVIEW_BACKOFFICE, { slug });
      }
      if (preview) {
        return Request(Constants.PRODUCT_PREVIEW, { slug });
      }
      if (draft) {
        return Request(Constants.PRODUCT_DRAFT, { slug });
      }
      if (unpublished) {
        return Request(Constants.PRODUCT_UNPUBLISHED, { slug });
      }
      if (reference) {
        return Request(Constants.PRODUCT_REFERENCE, { slug, reference });
      }
      return Request(Constants.PRODUCT, { slug });
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  async getProfileInternal(query, agent) {
    const { slug, preview, origin, draft, unpublished, reference } = query;

    try {
      if (preview && origin === 'backoffice') {
        return Request(
          Constants.PRODUCT_PREVIEW_BACKOFFICE_INTERNAL,
          { slug, origin },
          { httpsAgent: agent }
        );
      }
      if (preview) {
        return Request(
          Constants.PRODUCT_PREVIEW_INTERNAL,
          { slug },
          { httpsAgent: agent }
        );
      }
      if (draft) {
        return Request(
          Constants.PRODUCT_DRAFT_INTERNAL,
          { slug },
          { httpsAgent: agent }
        );
      }
      if (unpublished) {
        return Request(
          Constants.PRODUCT_UNPUBLISHED_INTERNAL,
          { slug },
          { httpsAgent: agent }
        );
      }
      if (reference) {
        return Request(
          Constants.PRODUCT_REFERENCE_INTERNAL,
          { slug, reference },
          { httpsAgent: agent }
        );
      }
      return Request(
        Constants.PRODUCT_INTERNAL,
        { slug },
        { httpsAgent: agent }
      );
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  getOtherProducts(reference, producerId, options) {
    const productConfig = {
      size: 6,
      randomize: true,
      page: 0,
      hotId: producerId,
      ...options
    };

    return Request(Constants.OTHER_PRODUCTS, { reference, ...productConfig });
  }

  getModulesClub(profile, moduleOrder) {
    try {
      const params = {
        ucode: profile?.product?.ucode,
        rows: MODULES_CLUB.MAX_IMPORTED,
        page: moduleOrder,
        offer: profile?.product?.offer
      };

      return Request(Constants.PRODUCT_MODULES, { ...params });
    } catch (e) {
      return [];
    }
  }

  async getErrorMock(status) {
    try {
      return Request(Constants.PROFILE_ERROR_MOCK, { status });
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  async getCheckout(profile, query, ip) {
    const isBlackNovember = Utils.isBlackNovember(profile.product);
    const coupon = isBlackNovember ? profile.product.coupon.code : query.coupon;
    const { productId } = profile.product;
    let { off } = query;

    if (profile?.product?.offer && !off) {
      off = profile.product.offer;
    }

    const offerCodeAttribute = off ? { orderOfferCode: off } : {};

    const checkoutData = {
      affiliationReference: [
        {
          productId,
          couponOfferCode: coupon || '',
          ...offerCodeAttribute
        }
      ],
      shopperIp: ip
    };

    try {
      const request = await Request(Constants.CHECKOUT, {
        ...checkoutData,
        attach_token: false,
        productId
      });
      return request;
    } catch (e) {
      try {
        // chamando checkout sem coupon caso o cupom seja invalido
        if (
          checkoutData?.coupon &&
          e?.response?.data?.error ===
            'order.checkout.orderprocessor.hotmart_checkout_error_hmc_024'
        ) {
          const queryNoCoupon = { ...query };
          delete queryNoCoupon.coupon;
          return this.getCheckout(profile, queryNoCoupon);
        }
      } catch (er) {
        console.log(er);
        throw er;
      }
      throw e;
    }
  }

  async getCheckoutPrice(profile, query, ip) {
    const isBlackNovember = Utils.isBlackNovember(profile.product);
    const blackNovemberCoupon = isBlackNovember
      ? profile.product.coupon.code
      : null;
    const coupon = query?.coupon || blackNovemberCoupon;
    const { producerReferenceCode } = profile.product;
    let { off } = query;

    if (profile?.product?.offer && !off) {
      off = profile.product.offer;
    }

    const offerCodeAttribute = off ? { offer: off } : {};

    const checkoutData = [
      {
        products: [
          {
            affiliation: producerReferenceCode,
            coupon: coupon || '',
            ...offerCodeAttribute
          }
        ],
        additionalData: {
          shopperIp: ip
        }
      }
    ];

    try {
      const request = await Request(Constants.PRODUCT_PRICE, checkoutData);
      return request;
    } catch (e) {
      sendAnalyticsEvent({
        event: 'noninteraction',
        event_category: 'Product:NoPrice:Gateway',
        event_label: `ProductPage - ${e}`
      });

      try {
        // chamando checkout sem coupon caso o cupom seja invalido
        if (
          checkoutData?.coupon &&
          e?.response?.data?.error ===
            'order.checkout.orderprocessor.hotmart_checkout_error_hmc_024'
        ) {
          const queryNoCoupon = { ...query };
          delete queryNoCoupon.coupon;
          return this.getCheckout(profile, queryNoCoupon);
        }
      } catch (er) {
        console.log(er);
        throw er;
      }
      throw e;
    }
  }

  async getInformation(token) {
    try {
      const user = await Request(Constants.AUTHCHECK, { access_token: token });
      return user;
    } catch (e) {}
  }

  async getFeatureSwitch(internal = false) {
    try {
      const featureSwitchResponse = internal
        ? await Request(Constants.FEATURES_SWITCH_INTERNAL)
        : await Request(Constants.FEATURES_SWITCH);
      const featureSwitch = {};

      if (featureSwitchResponse && Array.isArray(featureSwitchResponse)) {
        featureSwitchResponse.forEach((feature) => {
          if (
            feature &&
            feature.hasOwnProperty('key') &&
            feature.hasOwnProperty('value')
          ) {
            featureSwitch[feature.key] = feature.value;
          }
        });
      }
      return featureSwitch;
    } catch (e) {}
  }

  async getUser(token) {
    try {
      const user = await Request(Constants.USER, { access_token: token });
      return user;
    } catch (e) {}
  }

  async checkToken(token) {
    try {
      const user = await Request(Constants.CHECKTOKEN, { token });
      return user;
    } catch (e) {}
  }

  async getProductBought(id) {
    try {
      return Request(Constants.PRODUCT_BOUGHT, { id });
    } catch (e) {}
  }

  async getProductReviews(id) {
    try {
      return Request(Constants.PRODUCT_REVIEWS, { id });
    } catch (e) {
      console.log(e);
    }
  }

  async getProductReviewsAnswers(
    id,
    page_size = config.envConfig.REVIEWS.QTY_PER_PAGE,
    offset = 0,
    evaluation = 'ALL',
    sortAnswers
  ) {
    try {
      return Request(Constants.PRODUCT_REVIEWS_ANSWERS, {
        id,
        page_size,
        offset,
        evaluation,
        sort_by_answers: sortAnswers || false
      });
    } catch (e) {
      console.log(e);
    }
  }
}

export default new ProfileService();
