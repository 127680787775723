import React from 'react';
import { useTranslation } from "next-i18next";
import { Offer, Graph } from 'schema-dts';
import { jsonLdScriptProps } from 'react-schemaorg';
import { mountProductOffer, mountProductReview } from './options';
import Utils from 'global/scripts/utils';
import sanitizeHtml from 'sanitize-html';
import { convertStringToSearch } from 'utils/string';
import config from '../../../env.config';

type StructuredDataProps = {
  product: ProductInfo;
  checkout: DataCheckoutProps;
  reviews: CustomerReviewProps;
};

export const StructuredData = (props: StructuredDataProps) => {
  const { product } = props;
  const { t } = useTranslation()

  const aggregateRating = mountProductReview(props?.reviews);
  const offers = mountProductOffer(props.product, props.checkout) as Offer;
  const durationDefaultInHours = 0;
  const duration = `PT${product.dataSheet.duration || durationDefaultInHours}H`; // formato de duração ISO 8601
  const hotmartBaseUrl = config.envConfig.APP;

  const jsonLdData: Graph = {
    '@context': 'https://schema.org',
    '@graph': [
      {
        '@type': 'Course',
        publisher: {
          "@type": "Organization",
          name: "Hotmart",
          sameAs: hotmartBaseUrl,
          url: hotmartBaseUrl,
        },
        provider: {
          "@type": "Person",
          name: sanitizeHtml(product?.hotmarter?.name),
          url: `${Utils.mountMainUrlSalesPage(product.language)}/${product.slug}/${product.producerReferenceCode}`,
          sameAs: `${hotmartBaseUrl}/${Utils.verifyLanguage(product.language)}/marketplace/${Utils.prepareForProductPage(Utils.verifyLanguage(product.language))}?q=${convertStringToSearch(product.hotmarter?.name)}`
        },
        author: {
          "@type": "Person",
          name: sanitizeHtml(product.hotmarter?.name),
          image: product.hotmarter?.avatar || product.hotmarter?.avatarFinal || 'https://hotmart.com/product/static/images/placeholder/avatar.png?',
          jobTitle: `${t('product.creator')}`
        },
        audience: {
          "@type": "EducationalAudience",
          educationalRole: sanitizeHtml(product.dataSheet?.target || `${t('product.defaultTarget')}`), 
        },
        about: [
          {
            "@type": "Thing",
            name: sanitizeHtml(`${t(`subcategory.${product.category}`)}`),
          },
          {
            "@type": "Thing",
            name: sanitizeHtml(product.name),
          }
        ],
        "@id": `${Utils.mountMainUrlSalesPage(product.language)}/${product.slug}/${product.producerReferenceCode}`,
        name: sanitizeHtml(product.name?.slice(0, 120)),
        description: `${Utils.truncateText(product.copy)}`,
        isAccessibleForFree: false,
        image: product.avatarFinal || product.avatar || 'https://hotmart.com/product/static/images/placeholder/product.png',
        inLanguage: product.language,
        hasCourseInstance: {
          "@type": "CourseInstance",
          courseMode: [
            "distance learning",
            "online"
          ],
          ...(product.publicationDate && { startDate: product.publicationDate }),
          location: {
            "@type": "VirtualLocation",
            url: "https://consumer.hotmart.com/" 
          },
          inLanguage: product.language,
          instructor: {
            "@type": "Person",
            name: sanitizeHtml(product.hotmarter?.name),
            image: product.hotmarter?.avatar || 'https://hotmart.com/product/static/images/placeholder/avatar.png?',
          },
          courseWorkload: duration,
          ...(offers ? { offers } : {}),
        },
        ...(product.dataSheet.certification ? { educationalCredentialAwarded: {
          "@type": "EducationalOccupationalCredential",
          name: "Hotmart Certificate",
          credentialCategory: "Certificate",
          url: `https://help.hotmart.com/${Utils.verifyLanguage(product.language)}/article/115002437531/como-acessar-o-meu-certificado-`
        }} : {}), 
        ...(aggregateRating ? { aggregateRating } : {})
      }
    ]
  };

  return <script {...jsonLdScriptProps(jsonLdData)} />;
};

export default React.memo(StructuredData);
