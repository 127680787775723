import React, { useContext } from 'react';
import { withTranslation } from 'next-i18next';
import { ProfileContext } from '../core/Provider/ProfileProvider';
import DayPickerContainer from './DayPickerContainer';

export const DatePickerWrapper = (props) => {
  const {
    startDate,
    selectDate,
    highlightDays,
    locale,
    isSessionPackage,
    tooltip,
    setTooltip
  } = props;
  const context = useContext(ProfileContext);

  return (
    <div>
      <DayPickerContainer
        highlightDays={highlightDays}
        startDate={startDate}
        selectDate={selectDate}
        context={context}
        locale={locale}
        isSessionPackage={isSessionPackage}
        tooltip={tooltip}
        setTooltip={setTooltip}
        {...props}
      />
    </div>
  );
}

export default withTranslation()(React.memo(DatePickerWrapper));
