import React from 'react';


const Loader = props => (
    <div className="loader">
      <div className="profile-loading__icon">
        <div className="profile-loading__activity"></div>
        <div className="profile-loading__activity profile-loading__activity--revert"></div>
        <div className="profile-loading__fire">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 109.8 151.4">
            <path
              d="M109.7 96.6c.7-13.5-3.9-35.6-15.3-53.6-.3-.5-.8-.3-.7.2.8 2.8.9 7.7-3.4 7.4-7.8-.5.3-17.1-16.8-28.6-.2-.1-.4-.1-.5 0-.1.1-.1.3 0 .4 1.2 2.2 1.9 9.1-.8 11.5-2.2 1.9-6.1 1.4-10-4.8-6.4-10.4-4-21.5.4-28.4.3-.6-.2-.8-.6-.7-23.4 7.8-28.1 35.4-33.1 45.4-.8 1.7-1.6 2.6-3 2.5-4.3-.3-1.2-9.4.3-12.6.1-.2 0-.4-.1-.5-.1-.1-.3 0-.4.1C13.5 47.5 2.6 69 .5 88.9c.1-.5-.2 1.6-.3 3.2 0 .6-.1 1.2-.1 1.8-.1.8-.1 1.5-.1 2.3-.2 30.3 24.2 55 54.6 55.2 30.2.2 54.9-24.1 55.2-54.3 0-.2-.1-.4-.1-.5zm-55 25.4c-14-.1-25.3-11.6-25.2-25.7.1-14 11.6-25.3 25.7-25.2 14 .1 25.3 11.6 25.2 25.6-.2 14.2-11.7 25.5-25.7 25.3z"></path>
          </svg>
        </div>
      </div>
    </div>
);

export const MainLoader = props => {
  return props.loading &&
    <div className="loader">
      <div className="profile-loading__icon">
        <div className="profile-loading__activity"></div>
        <div className="profile-loading__activity profile-loading__activity--revert"></div>
        <div className="profile-loading__fire">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 109.8 151.4">
            <path
              d="M109.7 96.6c.7-13.5-3.9-35.6-15.3-53.6-.3-.5-.8-.3-.7.2.8 2.8.9 7.7-3.4 7.4-7.8-.5.3-17.1-16.8-28.6-.2-.1-.4-.1-.5 0-.1.1-.1.3 0 .4 1.2 2.2 1.9 9.1-.8 11.5-2.2 1.9-6.1 1.4-10-4.8-6.4-10.4-4-21.5.4-28.4.3-.6-.2-.8-.6-.7-23.4 7.8-28.1 35.4-33.1 45.4-.8 1.7-1.6 2.6-3 2.5-4.3-.3-1.2-9.4.3-12.6.1-.2 0-.4-.1-.5-.1-.1-.3 0-.4.1C13.5 47.5 2.6 69 .5 88.9c.1-.5-.2 1.6-.3 3.2 0 .6-.1 1.2-.1 1.8-.1.8-.1 1.5-.1 2.3-.2 30.3 24.2 55 54.6 55.2 30.2.2 54.9-24.1 55.2-54.3 0-.2-.1-.4-.1-.5zm-55 25.4c-14-.1-25.3-11.6-25.2-25.7.1-14 11.6-25.3 25.7-25.2 14 .1 25.3 11.6 25.2 25.6-.2 14.2-11.7 25.5-25.7 25.3z"></path>
          </svg>
        </div>
      </div>
    </div>
}

export default Loader;
