import Request from '@hotmart/request';
import config from '../../env.config';

export const Constants = {
  SEND_LEAD: 'lead.send',
  SEND_LEAD_LAUNCH: 'lead_launch.send',
  SEND_LEAD_CAPTURE: 'lead_capture.send'
};

Request.register(config.envConfig.LEAD_MASTERS, {
  post: {
    [Constants.SEND_LEAD]: '/v1/datahub'
  }
});

Request.register(config.envConfig.LEAD_LAUNCH, {
  post: {
    [Constants.SEND_LEAD_LAUNCH]: '/convert/:id'
  }
});

Request.register(config.envConfig.LEAD_CAPTURE, {
  post: {
    [Constants.SEND_LEAD_CAPTURE]: '/v1/lead'
  }
});
